@use '@angular/material' as mat;
@use './custom-theme' as *;

.mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: $card-primary !important;
}

.mat-mdc-table {
  background: $card-primary !important;
  box-shadow: none;
}

.mat-mdc-table tbody,
.mat-mdc-table tfoot,
.mat-mdc-table thead,
.mat-mdc-cell,
.mat-mdc-footer-cell,
.mat-mdc-header-row,
.mat-mdc-row,
.mat-mdc-footer-row,
.mat-mdc-table .mat-mdc-header-cell {
  font-family: 'Space Grotesk', sans-serif;
}

mat-dialog-actions,
.mat-mdc-dialog-action {
  padding: 1.5rem !important;

  .action-btn {
    background-color: $primary;
    margin-left: 1rem !important;
  }
}

mat-dialog-content {
  color: $white-secondary !important;
}

.mat-mdc-dialog-title {
  color: $white-primary !important;
}

//TODO: change the material theme color

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: $card-primary;
}

.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: $primary;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: $primary;
}

.mdc-text-field .mdc-text-field__input {
  caret-color: $primary;
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: $primary;
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: $primary;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: $primary;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: $primary;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  border-color: $primary !important;
  background-color: $primary !important;
}

// .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background{
//   border-color: $primary !important;
// }

.mat-datepicker-toggle-active {
  color: $primary;
}

// end of todo

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: $primary-25;
}

.mat-datepicker-content {
  background-color: $card-primary;
}

.mat-calendar-body-selected {
  background-color: $primary;
}

.mat-calendar-body-in-range {
  &::before {
    background: $primary-25;
  }
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: $white-primary !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: $green-primary;
}

mat-select-trigger,
.mat-mdc-tab,
mat-label,
.mat-mdc-checkbox label {
  font-family: 'Space Grotesk', sans-serif;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: $white-primary;
  font-weight: 700;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  font-weight: lighter;
}

.mdc-tab__ripple::before,
.mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-link .mat-ripple-element {
  background-color: $primary;
}

.mat-mdc-tab-labels {
  box-shadow: inset 0px -1px 0px 0px $grey-primary;
}

.mdc-evolution-chip-set__chips {
  flex-direction: column !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__checkmark {
  color: $background-primary !important;
}

.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected {
  span {
    color: $background-primary !important;
  }
}

.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: $primary-25;
}

.mdc-list-item__primary-text {
  &::first-letter {
    text-transform: uppercase;
  }
}

.mat-mdc-input-element,
.mat-mdc-dialog-title,
.mat-mdc-dialog-content,
button {
  font-family: 'Space Grotesk', sans-serif !important;
}

mat-select-trigger {
  mat-icon {
    height: 1rem !important;
    width: 1rem !important;
    min-width: 1rem;
    font-size: 1rem !important;
    margin-right: 1rem;
  }
}

.mdc-evolution-chip {
  margin: 0 !important;
}

mat-chip-set {
  span {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

mat-chip-option {
  width: 100%;
}

.mdc-evolution-chip__cell--primary {
  width: auto !important;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: $card-primary;
}

.mat-step-header .mat-step-icon-selected {
  background-color: $primary !important;
  color: $white-primary !important;
}

mat-step-header .mat-step-icon-state-edit {
  background-color: $primary !important;
  color: $white-secondary !important;
}

.mat-step-header .mat-step-icon {
  background-color: $white-secondary;
  color: $primary;
}
