$background-primary: #151419;
$background-secondary: #231f2c;
$background-tertiary: #34303e;

$card-primary: #23242c;
$card-skeleton: #32343f;

$primary: #8b5cf6;
$primary-50: #8b5cf680;
$primary-25: #8b5cf640;

$grey-primary: #898989;

$white-primary: #fff;
$white-secondary: #e9e9e9;

$green-primary: #8cd147;
$green-secondary: #263238;
$green-tertiary: #8cd14788;

$red-primary: #371818;
$error-primary: #f04665;
$red-secondary: #ff0000;

$warning-primary: #e9862a;

$black-primary: #000;
