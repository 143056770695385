@use '@angular/material' as mat;
@use './custom-theme' as *;

@import url('https://fonts.googleapis.com/css2?family=Mohave:wght@700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

body {
  height: 100vh;
  width: 100vw;
  font-family: 'Space Grotesk', sans-serif;
  background: $background-primary;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}

.filters-and-actions {
  display: flex;
  justify-content: space-between;

  mat-datepicker-toggle {
    button {
      background-color: transparent !important;
    }
  }

  button {
    background-color: $primary !important;
  }

  mat-form-field {
    width: 40%;
  }
}

::-webkit-scrollbar-track {
  border-radius: 0.375rem;
}

::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.375rem;
  background-color: $green-secondary;
}

.mat-typography {
  font:
    400 0.875rem/1.25rem 'Space Grotesk',
    sans-serif;
  letter-spacing: normal;
}

.mat-typography h1,
h2,
h3,
h4 {
  font-family: 'Space Grotesk', sans-serif;
}

mat-drawer-container {
  height: 93vh;
}

mat-drawer-content {
  background-color: $background-primary;
}

.mdc-menu-surface {
  background-color: $card-primary;
}

.mat-mdc-menu-content {
  background-color: $white-primary;

  ul {
    li {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      padding: 0.5rem 0.875rem;
      cursor: pointer;

      mat-icon {
        font-size: 1.1rem;
        height: 1.1rem;
        width: 1.1rem;
        margin-right: 0.875rem;
      }
    }

    .item-disabled {
      pointer-events: none;
      color: $grey-primary;
      cursor: default;
    }
  }
}

.custom-toast {
  margin-right: 5rem !important;

  .mdc-snackbar__surface {
    background-color: $card-primary !important;
  }
}

.Success {
  .mdc-snackbar__surface {
    border-right: 0.5rem solid $green-primary;
  }
}

.Error {
  .mdc-snackbar__surface {
    border-right: 0.5rem solid $error-primary;
  }
}

.Warning {
  .mdc-snackbar__surface {
    border-right: 0.5rem solid $primary;
  }
}

.mention-menu {
  background-color: $card-primary !important;

  .mention-item {
    color: $white-primary !important;
  }

  .mention-active {
    a {
      background-color: $primary !important;
    }
  }
}

.responsive-dialog {
  width: 80%;
  height: 80%;
}

.responsive-dialog-width {
  width: 80%;
}

.responsive-dialog-height {
  width: 80%;
}

.mat-mdc-form-field-subscript-wrapper {
  height: 0;
}

app-generate-report {
  mat-dialog-actions {
    padding: 0 !important;
  }
}

app-clients {
  .mat-expansion-panel {
    border-radius: 0;
    margin: 12px 0;
    background-color: #23242c;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-expansion-panel-header {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    .filters-and-actions {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      mat-form-field {
        width: 100%;
      }
    }
  }
}

app-list-invoices {
  @media (max-width: 768px) {
    .mdc-evolution-chip-set__chips {
      flex-direction: row !important;
      flex-flow: row;
      justify-content: space-between;

      .button {
        &:nth-child(n + 2) {
          display: none;
        }
      }

      .button {
        &:first-child {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}
